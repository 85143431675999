@import "./node_modules/bootstrap/scss/_functions";
@import "./node_modules/bootstrap/scss/_variables";
@import "./node_modules/bootstrap/scss/mixins/_breakpoints";
@import "./node_modules/bootstrap/scss/bootstrap";

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  font-weight: 200;
  font-style: normal;
  font-optical-sizing: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

img {
  border-radius: 8px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .view {
    background-color: white;
    border-radius: 20px;
    padding: 20px;
    min-width: 300px;
    max-width: 500px;
    overflow-y: hidden;

    &.big {
      width: 700px;
      max-width: 700px;
    }

    form {
      overflow-y: auto;
      max-height: 80vh;
      padding: 20px 20px 20px 0px;
    }

    @media screen and (min-width: 762px) {
      width: 500px;
    }
  }
}

.MuiTabs-flexContainer {
  align-items: center;
}

.MuiButton-root {
  border-radius: 20px !important;
}

.MuiFilledInput-root {
  background-color: transparent !important;
}

.MuiChip-label {
  font-weight: 300 !important;
}

.profile_tabs {
  a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #0e213b;
    text-decoration: none;
  }
}

.job-offer-apply {
  padding: 10px;
  a {
    text-decoration: none;
  }

  border-radius: 8px;
  &:hover {
    background-color: #eff2f6;
  }

  &.applicant {
    background-color: #eff2f6;
  }
}

.step-icon {
  height: 5rem !important;
  width: 5rem !important;
}

a {
  color: rgb(18 50 96);
}

.sidebar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;
  text-decoration: none;

  button {
    width: 150px;
  }

  @media screen and (min-width: 900px) {
    position: fixed;
    flex-direction: column;

    a,
    button {
      width: 100%;
    }
  }
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #eff2f6;
  border-radius: 20px;
}

::-webkit-scrollbar-thumb {
  background: #486284;
  border-radius: 20px;
}

.editor {
  margin-top: 20px;
  background: #eff2f6;
  padding: 20px;
  border-radius: 8px;
  position: relative;

  .editor-run {
    position: absolute;
    top: 4px;
    right: 20px;
  }
}

.MuiAccordion-root {
  background: #eff2f6 !important;

  .MuiAccordionSummary-content {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 5px;
  }
}
